import React from 'react';
import './Banner.css';
import { openModal } from './CustomModal';

function Banner() {
  return (
    <div className="w-full flex flex-col items-center p-4 bg-gray-100 min-h-fit" id="banner" style={{ height: "60vh" }}>
      <h6 className="mb-2">&nbsp;</h6>
      <div className="font-bold mb-2 text-white p-3 border-2 border-white text-1xl sm:text-xl md:text-2xl">
        GUÍA (EXPRÉS) GRATUITA
      </div>
      <p className="text-center text-white mb-16 font-bold text-2xl sm:text-3xl md:text-4xl lg:text-5xl mt-3">
        8 CLAVES PARA AFRONTAR SIN MIEDO <br />TU PROCESO DE DIVORCIO
      </p>
      <button className="custom-btn hover:cursor-pointer font-montserrat" onClick={openModal}>
        Descargar la Guía
      </button>
    </div>
  );
}

export default Banner;