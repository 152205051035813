import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import BrevoForm from './BrevoForm';

Modal.setAppElement('#root'); // Accessibility: Tells screen readers where your main content is

// State variables and modal controls stored externally
let openModalCallback = () => {};

// Main modal component
const CustomModal = () => {
  const [modalIsOpen, setIsOpen] = useState(false);

  // Reference to open modal
  openModalCallback = () => setIsOpen(true);

  function closeModal() {
    setIsOpen(false);
  }

  useEffect(() => {
    if (modalIsOpen) {
      document.body.classList.add('overflow-hidden');
    } else {
      document.body.classList.remove('overflow-hidden');
    }
  }, [modalIsOpen]);

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      className="fixed inset-0 flex items-center justify-center"
      overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
    >
      <div className="relative rounded-lg shadow-lg max-w-sm w-full" style={{backgroundColor: "#EFF2F7"}}>
        <button
          onClick={closeModal}
          className="absolute top-2 right-2 text-gray-700 text-4xl rounded-full p-2  focus:outline-none"
        >
<i class="bi bi-x-square"></i>
        </button>
        <BrevoForm/>
      </div>
    </Modal>
  );
};

// Export function to open the modal
export const openModal = () => {
  openModalCallback();
};

export default CustomModal;