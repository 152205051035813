import React, { useEffect } from 'react';

function BookAppointment() {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://assets.calendly.com/assets/external/widget.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="bg-white rounded-lg shadow-md flex flex-col items-center">
      <div className="pb-20">
        <div className="w-full">
          <div className="text-center mb-14">
            <h4 className="text-2xl sm:text-3xl font-bold tracking-wide uppercase">
              Agenda tu sesión de 45 minutos a continuación
            </h4>
          </div>
          <div
            id="myCalendly"
            className="calendly-inline-widget"
            data-url="https://calendly.com/divorciointeligente/reunion-tu-divorcio-inteligente"
            style={{ width: '100%', height: '1040px' }}
          ></div>
        </div>
      </div>
    </div>
  );
}

export default BookAppointment;