import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

function HeroContent1() {
  const [ref1, inView1] = useInView({ triggerOnce: true });
  const [ref2, inView2] = useInView({ triggerOnce: true });
  const [ref3, inView3] = useInView({ triggerOnce: true });
  const [ref4, inView4] = useInView({ triggerOnce: true });
  const [headerRef, headerInView] = useInView({ triggerOnce: true });

  return (
    <div className='w-full bg-gray-50' >
      <div className="pt-32 pb-32 pr-5 pl-5">
        <div className="w-full">
          <motion.div
            ref={headerRef}
            initial={{ opacity: 0, y: 50 }}
            animate={headerInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
            transition={{ duration: 0.5 }}
          >
            <div className="text-center pb-14">
              <h4 className="text-xl sm:text-2xl font-bold tracking-widest uppercase">
                ¿Qué tiene de especial <br/>esta guía?
              </h4>
            </div>
          </motion.div>
          <div className='w-full flex justify-center'>
            <div className="container flex flex-col md:flex-row justify-center items-center text-center text-base lg:text-lg" style={{display: "flex", gap:"20px"}}>
              <ul className="flex flex-col sm:flex-row items-center gap-8">
                <motion.li
                  ref={ref1}
                  className="flex flex-col items-center flex-grow"
                  initial={{ opacity: 0, y: 50 }}
                  animate={inView1 ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
                  transition={{ duration: 0.5, delay: 0.1 }}
                >
                  <img src="images/pic1.png" alt="" title="" className="mb-6 sm:mb-16" />
                  <p>
                    Lo que tienes que saber
                    <br /> antes de visitar a un
                    <br />
                    abogado para tu divorcio.
                  </p>
                </motion.li>
                <motion.li
                  ref={ref2}
                  className="flex flex-col items-center flex-grow"
                  initial={{ opacity: 0, y: 50 }}
                  animate={inView2 ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
                  transition={{ duration: 0.5, delay: 0.2 }}
                >
                  <img src="images/pic2.png" alt="" title="" className="mb-6 sm:mb-16" />
                  <p>
                    Un mapa de ruta para iniciar
                    <br />
                    tu proceso de divorcio con
                    <br />
                    rumbo seguro y las ideas claras.
                  </p>
                </motion.li>
                <motion.li
                  ref={ref3}
                  className="flex flex-col items-center flex-grow"
                  initial={{ opacity: 0, y: 50 }}
                  animate={inView3 ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
                  transition={{ duration: 0.5, delay: 0.3 }}
                >
                  <img src="images/pic3.png" alt="" title="" className="mb-6 sm:mb-16" />
                  <p>
                    Recursos prácticos que te harán
                    <br /> perder el temor a pensar en
                    <br />
                    la decisión que vas tomar.
                  </p>
                </motion.li>
                <motion.li
                  ref={ref4}
                  className="flex flex-col items-center flex-grow"
                  initial={{ opacity: 0, y: 50 }}
                  animate={inView4 ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
                  transition={{ duration: 0.5, delay: 0.4 }}
                >
                  <img src="images/pic4.png" alt="" title="" className="mb-6 sm:mb-16" />
                  <p>
                    Pautas para definir
                    <br /> lo que esperas de tu
                    <br /> proceso de divorcio.
                  </p>
                </motion.li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeroContent1;