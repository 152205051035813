import React from 'react'
import HeroContent2 from '../components/home/HeroContent2';
import HeroContent1 from '../components/home/HeroContent1';
import Banner from '../components/home/Banner';
import HeroContent3 from '../components/home/HeroContent3';
import CustomModal from '../components/home/CustomModal';

function Home() {
  return (
    <div>
      <Banner/>
      <HeroContent1/>
      <HeroContent2/>
      <HeroContent3/>
      <CustomModal/>
    </div>
  )
}

export default Home