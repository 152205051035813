import React from 'react';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <div id="footer" className='bg-gray-700 text-white'>
      <div className="p-6 container mx-auto flex flex-col items-center">
        <h6 className="text-lg font-bold ">
          <div className="flex flex-row justify-center items-center max-w-fit">
            <img src="images/DI-LOGOTIPO-2colores.png" width="88" height="88" alt="Tu Divorcio Inteligente" className="mx-auto mb-4"/>
            <span className="ml-2" style={{letterSpacing: "6px"}}>Tu Divorcio Inteligente</span>
          </div>
        </h6>

        <div className='container flex flex-col lg:flex-row justify-around mt-4 text-center gap-10'>
          
          <div className='pb-12'>
            <h6 className='font-light mb-2'>Contacta conmigo</h6>
            <div className="bg-white p-4 rounded shadow-md h-full mx-auto max-w-full" style={{width:"400px", height: "100%"}}>
              <form>
                <div className="mb-4">
                  <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">
                    Nombre
                  </label>
                  <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="name" type="text" placeholder="Tu nombre"/>
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
                    Email
                  </label>
                  <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="email" type="email" placeholder="Tu email"/>
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="message">
                    Mensaje
                  </label>
                  <textarea className="shadow appearance-none border rounded w-full h-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="message" placeholder="Tu mensaje"></textarea>
                </div>
                <div className="flex justify-end">
                  <button className="hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="button" style={{background:"#3aacaf"}}>
                    Enviar
                  </button>
                </div>
              </form>
            </div>
          </div>

          <div className='pb-12'>
            <h6 className='font-light mb-2'>Ubicación:</h6>
            <div className="w-full h-full flex flex-row justify-center">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3200.5755281859447!2d-4.5637254!3d36.660661!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd72e52d3d8a591d%3A0x3a840e33f816bd41!2sC.%20Punto%20Industrial%2C%201%2C%20Piso%201%C2%BAA%2C%2029130%20Alhaur%C3%ADn%20de%20la%20Torre%2C%20M%C3%A1laga!5e0!3m2!1sen!2ses!4v1731602051898!5m2!1sen!2ses"
                style={{width: "400px"}}
                height="100%"
                allowFullScreen=""
                loading="lazy"
                title="Business Location"
              ></iframe>
            </div>
          </div>

          <div>
            <h6 className='font-light'>Explora mis otros servicios:</h6>
            <div className="flex flex-col justify-around items-center gap-4 h-2/3">
              <a href='https://eugeniabravo.com/' target='_blank' className='hover:cursor-pointer'>
                <img className='h-16 w-36 object-contain' src='./images/eugeniabravologo.png'/>
                <label className='text-gray-200 underline font-light hover:cursor-pointer'>Eugenia Bravo</label>
              </a>
              <a href='https://www.planearespana.com/' target='_blank' className='hover:cursor-pointer'> 
                <img className='h-16 w-32 object-contain' src='./images/planearespanalogo.png'/>
                <label className='text-gray-200 underline font-light hover:cursor-pointer'>Planear España</label>
              </a>
            </div>
          </div>

        

        </div>
      </div>

      <div>
        <div className="container mx-auto flex flex-col items-center">
          <p className="text-sm text-gray-400">
            &copy; 2024 Tu Divorcio Inteligente. Todos los derechos reservados.
          </p>
          <div className="flex justify-center space-x-4 mt-2">
          <Link to="/privacy-policy" className="text-white">Privacy Policy</Link>
          <Link to="/cookies" className="text-white">Cookies</Link>
        </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;