import React from 'react';
import './HeroContent3.css';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

function HeroContentGracias() {
  const [ref1, inView1] = useInView({ triggerOnce: true });
  const [ref2, inView2] = useInView({ triggerOnce: true });
  const [headerRef, headerInView] = useInView({ triggerOnce: true });

  return (
    <div className='min-h-screen flex flex-col justify-center bg-white'>
      <div className="pt-32 pb-32 pr-5 pl-5">
        <div className="text-center m-14">
          <motion.h4
            ref={headerRef}
            className="text-2xl lg:text-3xl font-bold tracking-wide uppercase"
            initial={{ opacity: 0, y: 50 }}
            animate={headerInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
            transition={{ duration: 0.5 }}
          >
            ¿Te encuentras desorientado y necesitas<br />
            saber cómo puedes avanzar?
          </motion.h4>
        </div>
        <div className='w-full flex flex-row justify-center'>
          <div className="flex flex-col lg:flex-row items-center justify-center text-center gap-3 max-w-5xl p-2">
            <motion.div
              ref={ref1}
              className="pro-img lg:w-full mb-4 lg:mb-0 lg:mr-4"
              initial={{ opacity: 0, y: 50 }}
              animate={inView1 ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
              transition={{ duration: 0.5, delay: 0.1 }}
            >
              <p>
                <img
                  className="max-h-full max-w-full sm:max-h-96 sm:max-w-96 lg:max-w-full md:max-h-full mx-auto rounded-full"
                  src="images/pro-pic.png"
                  alt="Eugenia Bravo"
                  title="Eugenia Bravo"
                />
              </p>
              <p className='mt-3'>
                <em>
                  <strong>Eugenia Bravo</strong>
                </em>
                <br />
                <br />
                <em>Abogada y Mediadora Familiar</em>
                <br />
                <em>25 años de experiencia en gestión de divorcios</em>
              </p>
            </motion.div>
            <motion.div
              ref={ref2}
              className="inline-block align-top w-full md:w-3/5 text-justify text-lg italic text-gray-800"
              initial={{ opacity: 0, y: 50 }}
              animate={inView2 ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
              transition={{ duration: 0.5, delay: 0.2 }}
            >
              <p>
                Mi nombre es Eugenia. Soy abogada y mediadora familiar. <br />
                <br />
                He dedicado más de 20 años de mi profesión a guiar a familias en procesos de divorcio y orientándoles hacia una nueva etapa. Estudiando patrones, situaciones y reacciones de las personas, he creado mi propio método de trabajo que permita generar decisiones óptimas que faciliten las vivencias del proceso de divorcio. Lo he llamado, el proceso de divorcio inteligente.<br />
                <br />
                Puedo ayudarte en tu proceso de cambio. Para ello, te ofrezco una sesión de 45 minutos conmigo en la que dar tu primer paso hacia un divorcio inteligente. Conversaremos y te explicaré en detalle nuestro método para que puedas definir tu rumbo.
              </p>
            </motion.div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeroContentGracias;