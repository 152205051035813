import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import CookieConsent from 'react-cookie-consent';
import NavBar from './components/coreComponents/NavBar';
import Footer from './components/coreComponents/Footer';
import Gracias from './components/home/Gracias';
import Home from './pages/Home';
import PrivacyPolicy from './components/coreComponents/PrivacyPolicy';
import Cookies from './components/coreComponents/Cookies';
import ScrollToTop from './service/utils/ScrollToTop';
import './App.css';


function App() {
  return (
    <div className='bg-gray-700'>
      <Router>
        <NavBar />
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/gracias" element={<Gracias />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/cookies" element={<Cookies />} />
        </Routes>
        <Footer />
        {/* <CookieConsent
        location="bottom"
        buttonText="I understand"
        cookieName="myAwesomeCookieName"
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        expires={150}
      >
        This website uses cookies to enhance the user experience.{" "}
        <Link to="/cookies" style={{ color: "#ffffff" }}>Learn more</Link>
      </CookieConsent> */}
      </Router>

    </div>
  );
}

export default App;