import React from 'react';
// import './Form.css'; // Import your custom CSS

function BrevoForm() {
  return (
    <div className="sib-form text-cente">
      <iframe
        width="540"
        height="500"
        // https://531bf0c3.sibforms.com/serve/MUIFAD41_3fWYrTEA8uJfgbpWL_CgnPt5135uzICzGtJl9VWsg4czHmIZ5fwBbVSYzZfVjDxl5gFbnD1UBJeYDg-Q6ibKYSZijAcI7XUxP6_nr7r_cHA31v2JDBui52xXkxTrBcAD-YimtEgsZojjEjr1z23OU4_oL7WvFgA5GHHMp7FdMyXMgNzu7mlbAca9kia7z51nqY9-U5y
        src="https://531bf0c3.sibforms.com/serve/MUIFAC5KagDhqTX8mJ5LcPd51Epzs8C7kMiUyEcChL0s_ZUP3IG3et1wg2lOmyPBXFCqzS7gj9NSMjFMv8oyOpTD9Achziyrjer6dZbfz20j4DyfWDZhsY899BNN5eN70Kl2VAAKh9i2Q9BxMn9pbC7Fgxgxno6qv0QjHx_I-29lfpD5GiN2U51uI4PXnsVzVypxoYRPiHq0wZjf"
        frameBorder="0"
        scrolling="auto"
        allowFullScreen
        className="block mx-auto max-w-full"
      ></iframe>
    </div>
  );
}

export default BrevoForm;