import React from 'react';
import BannerGracias from './Banner-Gracias';
import BookAppointment from './BookAppointment';
import HeroContentGracias from './HeroContentGracias';

function Gracias() {
  return (
    <div className='w-full h-full flex flex-col'>
      <BannerGracias />
      <HeroContentGracias />
      <BookAppointment />
    </div>
  );
}

export default Gracias;