import React, { createContext, useState, useContext } from 'react';

const PrivacyPolicyContext = createContext();

export const PrivacyPolicyProvider = ({ children }) => {
  const [isPrivacyPolicyAccepted, setIsPrivacyPolicyAccepted] = useState(false);

  const acceptPrivacyPolicy = () => {
    setIsPrivacyPolicyAccepted(true);
  };

  return (
    <PrivacyPolicyContext.Provider value={{ isPrivacyPolicyAccepted, acceptPrivacyPolicy }}>
      {children}
    </PrivacyPolicyContext.Provider>
  );
};

export const usePrivacyPolicy = () => {
  return useContext(PrivacyPolicyContext);
};