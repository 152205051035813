import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

function HeroContent2() {
  const [ref1, inView1] = useInView({ triggerOnce: true });
  const [ref2, inView2] = useInView({ triggerOnce: true });
  const [ref3, inView3] = useInView({ triggerOnce: true });
  const [headerRef, headerInView] = useInView({ triggerOnce: true });

  return (
    <div className='bg-white w-full'>
      <div className="pt-32 pb-32 pr-5 pl-5">
        <div className="w-full">
          <motion.div
            ref={headerRef}
            initial={{ opacity: 0, y: 50 }}
            animate={headerInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
            transition={{ duration: 0.5 }}
          >
            <div className="text-center pb-14">
              <h4 className="text-xl sm:text-2xl font-bold tracking-widest uppercase">
                ¿Qué tiene de especial <br/>esta guía?
              </h4>
            </div>
          </motion.div>
          <div className='w-full flex justify-center'>
            <div className="container flex flex-col md:flex-row justify-center items-center text-center text-base lg:text-lg" style={{display: "flex", gap:"20px"}}>
              <ul className="flex flex-col sm:flex-row items-center gap-8">
                <motion.li
                  ref={ref1}
                  className="flex flex-col items-center flex-grow"
                  initial={{ opacity: 0, y: 50 }}
                  animate={inView1 ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
                  transition={{ duration: 0.5, delay: 0.1 }}
                >
                  <img src="images/pic01.png" alt="" title="" className="mb-6 sm:mb-16" />
                  <p>
                    Te ofrece listados que te ayudarán
                    <br /> a ordenar tus ideas y a reconocer todo
                    <br />
                    lo que debes tener presente para divorciarte.
                  </p>
                </motion.li>
                <motion.li
                  ref={ref2}
                  className="flex flex-col items-center flex-grow"
                  initial={{ opacity: 0, y: 50 }}
                  animate={inView2 ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
                  transition={{ duration: 0.5, delay: 0.2 }}
                >
                  <img src="images/pic02.png" alt="" title="" className="mb-6 sm:mb-16" />
                  <p>
                    Plantea preguntas que te darán
                    <br /> información para tomar decisiones
                    <br />
                    durante tu proceso de divorcio y después.
                  </p>
                </motion.li>
                <motion.li
                  ref={ref3}
                  className="flex flex-col items-center flex-grow"
                  initial={{ opacity: 0, y: 50 }}
                  animate={inView3 ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
                  transition={{ duration: 0.5, delay: 0.3 }}
                >
                  <img src="images/pic03.png" alt="" title="" className="mb-6 sm:mb-16" />
                  <p>
                    Sugiere herramientas que
                    <br />
                    podrás usar antes de encarar
                    <br />
                    tu proceso de divorcio.
                  </p>
                </motion.li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeroContent2;