import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

function NavBar() {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleLinkClick = () => {
    window.scrollTo(0, 0);
  };

  return (
    <nav className={`z-50 p-4 h-20 sticky top-0 transition-all duration-300 bg-gray-700 ${isScrolled ? 'bg-opacity-50 backdrop-blur-md' : 'bg-opacity-100'}`}>
      <div className="container mx-auto flex justify-between items-center">
        <Link to="/" onClick={handleLinkClick}>
          <div className="flex flex-row justify-center items-center max-w-fit">
            <img src="images/DI-LOGOTIPO-2colores.png" width="44" height="44" alt="Tu Divorcio Inteligente" className="mx-auto"/>
            <h6 className="ml-2 text-lg font-bold text-white" style={{letterSpacing: "6px"}}>Tu Divorcio Inteligente</h6>
          </div>
        </Link>
      </div>
    </nav>
  );
}

export default NavBar;