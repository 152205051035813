import React from 'react';
import './Banner.css';
import { openModal } from './CustomModal';

function BannerGracias() {
  return (
    <div className="w-full flex flex-col items-center p-4 bg-gray-100 min-h-fit" id="banner" style={{ height: "60vh" }}>
      <div className='flex flex-col text-center m-2 items-center'>
      <h6 className="mb-2">&nbsp;</h6>
      <p className="text-center text-white  mb-16 font-bold text-2xl sm:text-3xl md:text-4xl lg:text-5xl mt-3">
      ¡Gracias por tu interés en nuestra propuesta!</p>
<div className='w-fit'>
      <p className='font-bold p-1 text-white bg-gray-400 bg-opacity-5 backdrop-blur-md shadow-md rounded sm:text-base md:text-lg lg:text-xl text-center'>Te hemos enviado un email con nuestra Guía de las 8 claves para afrontar sin miedo tu<br/>divorcio para que la descargues y empieces a utilizar las herramientas que te propongo.</p>
      </div>
      </div>
    </div>
  );
}

export default BannerGracias;
