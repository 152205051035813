import React from 'react';
import './HeroContent3.css';
import { openModal } from './CustomModal';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

function HeroContent3() {
  const [ref1, inView1] = useInView({ triggerOnce: true });
  const [ref2, inView2] = useInView({ triggerOnce: true });
  const [headerRef, headerInView] = useInView({ triggerOnce: true });

  return (
    <div className='min-h-screen flex flex-col justify-center bg-gray-50'>
      <div className="pt-32 pb-32 pr-5 pl-5">
        <div className="text-center m-14">
          <motion.h4
            ref={headerRef}
            className="text-2xl lg:text-3xl font-bold tracking-wide uppercase"
            initial={{ opacity: 0, y: 50 }}
            animate={headerInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
            transition={{ duration: 0.5 }}
          >
            ¿Por qué descargar<br /> esta Guía?
          </motion.h4>
        </div>
        <div className='w-full flex flex-row justify-center'>
        <div className="flex flex-col lg:flex-row items-center justify-center text-center gap-3 max-w-5xl p-2">
            <motion.div
              ref={ref1}
              className="pro-img lg:w-full mb-4 lg:mb-0 lg:mr-4"
              initial={{ opacity: 0, y: 50 }}
              animate={inView1 ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
              transition={{ duration: 0.5, delay: 0.1 }}
            >
              <p>
                <img
                  className="max-h-full max-w-full sm:max-h-96 sm:max-w-96 lg:max-w-full md:max-h-full mx-auto rounded-full"
                  src="images/pro-pic.png"
                  alt="Eugenia Bravo"
                  title="Eugenia Bravo"
                />
              </p>
              <p className='mt-3'>
                <em>
                  <strong>Eugenia Bravo</strong>
                </em>
                <br />
                <br />
                <em>Abogada y Mediadora Familiar</em>
                <br />
                <em>25 años de experiencia en gestión de divorcios</em>
              </p>
            </motion.div>
            <motion.div
              ref={ref2}
              className="inline-block align-top w-full md:w-3/5 text-justify text-lg italic text-gray-800"
              initial={{ opacity: 0, y: 50 }}
              animate={inView2 ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
              transition={{ duration: 0.5, delay: 0.2 }}
            >
              <p>
                Internet está llena de información sobre lo que dice la ley respecto al divorcio y todos los temas asociados a la ruptura matrimonial: la custodia de los hijos, la pensión de alimentos, la división de los bienes y, en general, se encuentran consejos sobre qué hacer para evitar un proceso largo y tedioso, teniendo en cuenta lo que la ley dice y los jueces interpretan. <br />
                <br />
                Aquí te propongo que te centres en ti, en tu realidad, necesidad y tu deseo antes de tomar en cuenta “lo que debe ser” tu divorcio según las leyes y los jueces. <br />
                <br />
                Quiero guiarte para que desde el primer minuto sepas qué tener en cuenta, cómo puedes organizarte y ordenar tus ideas para lograr un divorcio a tu medida y obtener la información precisa para darle a tu asesor.
              </p>
            </motion.div>
        </div>
        </div>
        <div className='w-full flex flex-row justify-center mt-20'>
        <button className="custom-btn hover:cursor-pointer font-montserrat" onClick={openModal}>
        Descargar la Guía
      </button>
      </div>
      </div>
    </div>
  );
}

export default HeroContent3;